import WhiteSignature from "../Assets/Signature-white.png"
// import Links from "../Assets/Links.png"
import ProfilePic from "../Assets/profile-pic-2.png"
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga";

function LinksPage() {

  const navigate = useNavigate();
  const onClick = (link, label) =>{
    window.open(link, "_blank")
    ReactGA.event({
        category: "Links Button",
        action: "Click",
        label: label
    })
  }
  const onClickPortfolio = () => {
    ReactGA.event({
        category:"Links Button",
        action: "Click",
        label: "Portfolio"
    })
    navigate("/")
  }

  return (
    <>
      <div className="background-image"/>
      <div className="background-gradient"/>
      <div className="links-page-container">
        <nav>
          <img className="profile-pic" src={ProfilePic} alt="Logo" />
          <img className="signature" src={WhiteSignature} alt="Logo" />
        </nav>
        <div className="links-desc">Check out my links</div>
        <div className="link-btn-container">
          <div className="link-btn" onClick={() => onClickPortfolio()}>Portfolio</div>
          <div className="link-btn"  onClick={()=> onClick("https://www.linkedin.com/in/landon-germain-tipantiza-5304001b3/", "linkedin")}>Linkedin</div>
          <div className="link-btn"  onClick={()=> onClick("https://github.com/tipantiza", "github")}>Github</div>
          {/* <div className="link-btn"onClick={()=> window.open("https://www.instagram.com/tipantiza/", "_blank")}  >Instagram</div>
          <div className="link-btn" onClick={()=> window.open("https://open.spotify.com/user/landongt?si=9d0d3dc708ba4f4c", "_blank")} >Spotify</div> */}
        </div>
        
        {/* <img className="links app-link" src={Links} alt="Logo" /> */}
      </div>


    </>
  );
}

export default LinksPage;
