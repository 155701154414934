import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import TheEnd from "../Assets/theEnd.png"

export const data = [
    {
        id:0,
        titleText: "Hey, I'm Landon",
        description: "Check out my bio to get to know me and how I started in software development!",
        tags: ["About"],
        coverImageSrc: "landonJump.jpg",
        openedImage: "wide-angle-jump.png",
        info:true,
        images: [

        ] ,
        width:'20%',
        bodyHTML: (
            <>
                {/* <Card.Title className="card-title-custom">Hey, I'm Landon!</Card.Title>
                <div className="pill-container-custom">
                    <div className={"pill-button pill-selected smallish"}>About</div>
                </div> */}
                <Card.Text className="paragraph-1">
                    Hey, I’m Landon and I’m a full-stack software developer. Being raised in the Saskatchewan prairies with long winters and spending extended periods of time abroad with my family in South America, I have had a lot of time to work on creativity, diversity, and problem-solving and I’ve grown to love it! 
                    </Card.Text>
                <Card.Text className="paragraph-1">
                    
                    I have my uncle to thank for sparking my interest in computer programing. When he told me about this field I got really excited and started to learn how to create video games using Unity. Later my uncle was able to get me an opportunity to job shadow the lead developer and CPO, Joel Penner, at his company, <a href="https://grayboxsolutions.com/" target="_blank">Greybox Solutions</a>. That only fueled my desire to keep learning more!
                    </Card.Text>
                    
                <Card.Text className="paragraph-1">

                    I started taking software development more seriously when I enrolled in <Link to="/?project=11">Lighthouse labs</Link> in their Full-time Web development bootcamp. This bootcamp slingshotted me through learning much more practical technologies like React, SQL, HTML, CSS and JQuery just to name a few. This led to me landing my first job as a software developer at <Link to="/?project=2">Mapster</Link>!
                    </Card.Text>
                    <Card.Text className="paragraph-1">

                    All these experiences have contributed to my wanting to continue to learn more and more about software development. Currently, I’m learning java and spring-boot by <Link to="/?project=5">AmigosCode</Link> and I'm loving it! I look forward to working on fun projects and sharpening my skills as a software developer. 
                </Card.Text>
                {/* <Card.Text>
                </Card.Text> */}
                <img className="end-image" src={TheEnd} alt="Logo" />
            </>
        ),
    },
    {
        id:1,
        titleText: "Ditidaht Kids",
        description: "Check out this mobile game I helped create!",
        tags: ["Unity", "C#"],
        coverImageSrc: "DitidahtKids.png",
        openedImage: "DDKGamePlay.PNG",
        width:'20%',
        images: [

        ] , bodyHTML: (
            <>
                <Card.Text className="paragraph-1">
                    While working for Mapster I got to help lead in developing this mobile game. This game was specifically designed to teach about the culture and language of the Ditidaht nation. This is one of my favourite projects to date. I hope to work on similar projects in the future.
                </Card.Text>
                <Card.Text className="paragraph-1">
                    <a href="https://apps.apple.com/ca/app/ditidaht-kids/id1539465546" target="_blank">Download Game</a>
                </Card.Text>
                <img className="end-image" src={TheEnd} alt="Logo" />
            </>
        ),
    },
    {
        id:2,
        titlePNG: "mapsterLogo.png",
        titleText: null,
        innerTitle:"Mapster",
        description: "Check out my experience at Mapster.",
        tags: ["About", "Mapbox", "JS", "CSS", "HTML", "Ruby", "React", "PSQL", "Express", "PHP", "Unity", "C#", "Wordpress"],
        coverImageSrc: null,
        openedImage: "MapsterWebsite.png",
        width:'60%',
        bodyHTML: (
            <>
                <Card.Text className="paragraph-1">
                This was my first full-time job as a software developer. Here I got so many opportunities to work on a variety of projects. Mapster specializes in mapping solutions leveraging technologies such as React and Mapbox. Mapster also loved to take on projects to try out new technologies, I've been able to work on projects created with different technologies then what we are custom to using such as...
                </Card.Text>
                <Card.Text className="paragraph-1">
                    <lu>
                        <li>Kotlin</li>
                        <li>Swift</li>
                        <li>Svelte</li>
                        <li>Unity</li>
                        <li>C#</li>
                    </lu>
                </Card.Text>
                <Card.Text className="paragraph-1">
                    <a href="https://mapster.me/" target="_blank">Check out Mapster</a>

                </Card.Text>

                <img className="end-image" src={TheEnd} alt="Logo" />
            </>
        ),
        images: [

        ] 
    },
    {
        id:11,
        titleText: "Lighthouse Labs",
        description: "This is what help launch me into working as a software developer.",
        tags: ["About", "JS", "CSS", "HTML", "Ruby", "React", "PSQL", "Express"],
        titlePNG: null,
        coverImageSrc: "lhl-logo.jpeg",
        openedImage: "lighthouselabs.jpeg",
        width:'20%',
        images: [

        ],
        bodyHTML: (
            <>
                <Card.Text className="paragraph-1">
                    I took the Full-time Web Development Bootcamp at Lighthouse Labs. This course taught me all the foundations of Web development which helped me get a job at Mapster. We were the first cohort during covid, meaning we were the first all-remote class at Lighthouse labs. This was so useful because many software development positions are remote. So we learned how to communicate and interact with a team and mentors through great applications such as Slack, Discord, Zoom, Google Meet etc... Another useful skill that Lighthouse Labs taught me was how to learn. Software development is constantly changing and adapting so to be able to continue to learn is such an asset to keep progressing as a software developer.
                </Card.Text>
                <Card.Text className="paragraph-1">
                    This was a high-intensity and fast-paced course where we learn technologies like...
                </Card.Text>
                <Card.Text className="paragraph-1">
                    <lu>
                        <li>React</li>
                        <li>Javascript</li>
                        <li>PSQL</li>
                        <li>Ruby</li>
                        <li>EJS</li>
                        <li>HTML</li>
                        <li>CSS</li>
                        <li>SASS</li>
                        <li>Nodejs</li>
                    </lu>
                </Card.Text>
                <Card.Text className="paragraph-1">
                    <a href="https://www.lighthouselabs.ca/" target="_blank">Check out Lighthouse Labs</a>

                </Card.Text>

                <img className="end-image" src={TheEnd} alt="Logo" />
            </>
        ),
    },
    {
        id:10,
        titleText: "Parking 920",
        description: "Check out this parking management app that I got to help create.",
        tags: ["React", "Express", "PSQL", "Mapbox"],
        coverImageSrc: null,
        titlePNG: "parking920.png",
        openedImage: "parking920interface.jpeg",
        width:'20%',
        images: [

        ],
        bodyHTML: (
            <>
                <Card.Text className="paragraph-1">
                    This was such a cool project to work on. I got to work with students from UMBC to develop the interface and navigation process for this fantastic parking spot finder app. This app was created for students and staff to find real-time open parking spaces. Still in the beta version but such a cool concept. I would love for something like this to become more popular because of how much time it could save when trying to find a spot.
                </Card.Text>
                <Card.Text className="paragraph-1">
                    Tech:
                    <lu>
                        <li>React</li>
                        <li>Mapbox</li>
                        <li>PSQL</li>
                        <li>Express</li>
                    </lu>
                </Card.Text>
                <Card.Text className="paragraph-1">
                    <a href="https://apps.apple.com/ca/app/parking920/id1564115943" target="_blank">Download App</a>
                </Card.Text>
                <img className="end-image" src={TheEnd} alt="Logo" />
            </>
        ),
    },
    {
        id:4,
        titleText: "Bad Directions",
        description: "Create and edit maps! But the directions will be slightly off! Go out and explore! AKA Wiki-maps",
        tags: ["JS", "HTML", "CSS"],
        coverImageSrc: null,
        images: [

        ],
        openedImage: "wikimaps.png",
        width:'20%',
        bodyHTML: (
            <>
                <Card.Text className="paragraph-1">
                    This was my very first web project and first opportunity to use an API! Collaborating with a team of three we set out to create a website where you can create and edit maps that contain your favourite locations. The twist is that the directions will be slightly off to encourage being outdoors and exploring!
                </Card.Text>
                <Card.Text className="paragraph-1">
                    Tech:
                    <lu>
                        <li>Google Maps Api</li>
                        <li>JQuery</li>
                        <li>EJS</li>
                        <li>PSQL</li>
                        <li>HTML</li>
                        <li>CSS</li>
                    </lu>
                </Card.Text>
                <Card.Text className="paragraph-1">
                    <a href="https://github.com/BDeWitt-Cohen/wiki-map-midterm" target="_blank">Repo</a>
                </Card.Text>

                <img className="end-image" src={TheEnd} alt="Logo" />
            </>
        ),
    },
    {
        id:5,
        titleText: "Amigos Code",
        description: "Check out what I'm learning now.",
        tags: ["Java", "Spring Boot", "About"],
        coverImageSrc: null,
        titlePNG:  "amigoscode.png",
        openedImage: "JavaInterface.png",
        width:'20%',
        images: [

        ],
        bodyHTML: (
            <>
                <Card.Text className="paragraph-1">
                    I'm adding Java to my skills! I'm currently taking a course created by <a href="https://amigoscode.com/p/java-master-class" target="_blank">AmigosCode</a> to learn Java and Spring Boot. Check back again soon I'll have a Java project to show off here.
                </Card.Text>
                <img className="end-image" src={TheEnd} alt="Logo" />
            </>
        ),
    },
    {
        id:6,
        titleText: "Atlas Jams",
        description: "Trying to find musicians for the next wine night? Created with React and Ruby!",
        tags: ["React", "Ruby", "CSS", "PSQL"],
        coverImageSrc: "atlasJams.png",
        openedImage: "atlasjams2.png",
        width:'20%',
        images: [

        ],
        bodyHTML: (
            <>
                <Card.Text className="paragraph-1">
                    Collaborating with a team of 3 we created our final project for Lighthouse Labs called "Atlas Jams". This is an MVP website design to help bridge the gap between musicians and hosts. This website would help you to plan events and hire musicians. It would also help musicians promote themselves and find gigs!
                </Card.Text>
                <Card.Text className="paragraph-1">
                    Tech:
                    <lu>
                        <li>React</li>
                        <li>Ruby</li>
                        <li>CSS</li>
                        <li>PSQL</li>
                    </lu>
                </Card.Text>
                <Card.Text className="paragraph-1">
                    <a href="https://github.com/jalaynatipantiza/atlas-Jams" target="_blank">Repo</a>
                </Card.Text>
                <img className="end-image" src={TheEnd} alt="Logo" />
            </>
        ),
    },
    // {
    //     id:7,
    //     titleText: "Global Response to covid 19",
    //     description: "Global Responses to COVID-19 A law and economics comparative project",
    //     tags: ["Svelt", "CSS"],
    //     coverImageSrc: null,
    //     images: [

    //     ],
    //     bodyHTML: (
    //         <>
    //             <Card.Title className="card-title-custom">Check it, I worked for  <img src={ProfilePic} alt="Logo" /></Card.Title>
    //             <div className="pill-container-custom">
    //                 <div className={"pill-button pill-selected smallish"}>About</div>
    //                 <div className={"pill-button pill-selected smallish"}>Mapbox</div>
    //             </div>
    //             <Card.Text className="paragraph-1">
    //                 <img src={MapsterImage} alt="Logo" />
    //                 Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque porta dolor nec velit faucibus, ut porttitor ante dignissim. Morbi eget justo sem. Praesent a quam et mi faucibus efficitur. Sed tincidunt mi neque, non ultricies felis porta et. Nam vehicula aliquet nunc eu accumsan. Nulla facilisi. Nunc aliquam scelerisque ultrices. Sed mollis, velit sit amet mollis interdum, massa purus pellentesque nisi, sed commodo nibh enim eget arcu. Sed vulputate turpis sit amet diam lobortis commodo. Nunc tincidunt arcu sit amet dolor bibendum, tempus mattis nulla gravida. Nulla non quam velit. Aliquam dictum metus mollis, viverra mi in, pretium nisi. Nullam accumsan neque mattis, rutrum ipsum non, vulputate augue. Suspendisse potenti. Nulla hendrerit, nisi vel eleifend condimentum, enim magna efficitur eros, ac efficitur ex risus sed odio. Fusce ut felis id urna consectetur gravida.
    //             </Card.Text>
    //             <Card.Text>
    //                 Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque porta dolor nec velit faucibus, ut porttitor ante dignissim. Morbi eget justo sem. Praesent a quam et mi faucibus efficitur. Sed tincidunt mi neque, non ultricies felis porta et. Nam vehicula aliquet nunc eu accumsan. Nulla facilisi. Nunc aliquam scelerisque ultrices. Sed mollis, velit sit amet mollis interdum, massa purus pellentesque nisi, sed commodo nibh enim eget arcu. Sed vulputate turpis sit amet diam lobortis commodo. Nunc tincidunt arcu sit amet dolor bibendum, tempus mattis nulla gravida. Nulla non quam velit. Aliquam dictum metus mollis, viverra mi in, pretium nisi. Nullam accumsan neque mattis, rutrum ipsum non, vulputate augue. Suspendisse potenti. Nulla hendrerit, nisi vel eleifend condimentum, enim magna efficitur eros, ac efficitur ex risus sed odio. Fusce ut felis id urna consectetur gravida.
    //             </Card.Text>
    //             <img className="end-image" src={TheEnd} alt="Logo" />
    //         </>
    //     ),
    // },
    {
        id:8,
        titleText: "Scheduler",
        description: "My first React App",
        tags: ["React"],
        coverImageSrc: "scheduler.png",
        openedImage: "scheduler.png",
        width:'20%',
        images: [

        ],
        bodyHTML: (
            <>
                <Card.Text className="paragraph-1">
                    This was my very first React app so I have a bit of nostalgia for it. This is a simple react app where you can schedule appointments. I got to experiment with testing in React on this app and it really showed me how fun React can be.
                </Card.Text>
                <Card.Text className="paragraph-1">
                    Tech:
                    <lu>
                        <li>React</li>
                        <li>Jest</li>
                        <li>SASS</li>
                    </lu>
                </Card.Text>
                <Card.Text className="paragraph-1">
                    <a href="https://github.com/tipantiza/scheduler" target="_blank">Repo</a>
                </Card.Text>
                <img className="end-image" src={TheEnd} alt="Logo" />
            </>
        ),
    },
    {
        id:9,
        titleText: "Winslip",
        description: "Check out my first wordpress custom theme.",
        tags: ["PHP", "HTML", "CSS", "Wordpress"],
        coverImageSrc: "winslip-logo.png",
        openedImage: "winslip.png",
        width:'20%',
        images: [

        ],
        bodyHTML: (
            <>
                <Card.Text className="paragraph-1">
                    This is a simple website for New West Winslip displaying the about, news and resources for their site. Although created with WordPress, I created a custom theme for this site. Learning how to create a custom theme has really taught me a lot about how WordPress works and how to create a more complex website by being in control of the theme.
                </Card.Text>
                <Card.Text className="paragraph-1">
                    Tech:
                    <lu>
                        <li>Wordpress</li>
                        <li>PHP</li>
                        <li>HTML</li>
                        <li>CSS</li>
                    </lu>
                </Card.Text>
                <Card.Text className="paragraph-1">
                    <a href="http://www.wins-lip.ca/" target="_blank">Site</a>
                </Card.Text>
                <img className="end-image" src={TheEnd} alt="Logo" />
            </>
        ),
    },
    {
        id:3,
        titleText: "If not us then who?",
        description: "This is an app with informative documentary like videos.",
        tags: ["React"],
        coverImageSrc: "INUTW.png",
        openedImage: "INUTW.png",
        width:'20%',
        images: [

        ] ,
        bodyHTML: (
            <>
                <Card.Text className="paragraph-1">
                    This was a neat project to work on because of the mission of the app. This app was created for isolated communities where an internet connection is scarce. This app manages these documentary-like videos so that they are available to download when the user has an internet connection then the video can be played when there is no internet connection.
                </Card.Text>
                <img className="end-image" src={TheEnd} alt="Logo" />
            </>
        ),
    },
    
]