import { useNavigate } from "react-router-dom"
import linkedin from "../Assets/linkedin-white.png"
import Github from "../Assets/github.png"
// import Spotify from "../Assets/spotify.png"
// import { Instagram } from "react-bootstrap-icons"
import ReactGA from "react-ga";

export const Socials = () => {
    const navigate = useNavigate();
    const onClick = (link, label) =>{
        window.open(link, "_blank")
        ReactGA.event({
            category: "Button",
            action: "Click",
            label: label
        })
    }
    const onClickNavigate = () => {
        ReactGA.event({
            category:"Button",
            action: "Click",
            label: "Link"
        })
        navigate("/links")
    }

    return (
        <div className="socials">
            <img className="linkedin app-link" onClick={()=> onClick("https://www.linkedin.com/in/landon-germain-tipantiza-5304001b3/", "linkedin")} src={linkedin} alt="Logo" />
            <img className="github app-link" onClick={()=> onClick("https://github.com/tipantiza", "github")}  src={Github} alt="Logo" />
            {/* <img className="spotify app-link" onClick={()=> window.open("https://open.spotify.com/user/landongt?si=9d0d3dc708ba4f4c", "_blank")}  src={Spotify} alt="Logo" /> */}
            {/* <Instagram color="white" onClick={()=> window.open("https://www.instagram.com/tipantiza/", "_blank")} className="insta app-link"></Instagram> */}
            {/* <img className="links app-link" onClick={()=> navigate("/links")}  src={Links} alt="Logo" /> */}
            <p className="links app-link" onClick={() => onClickNavigate()} > Links</p>
        </div>
    )
}