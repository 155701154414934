import { Card, CloseButton, Modal } from "react-bootstrap"

export const CardModal = ({showCardModal, handleCloseCardModal, bodyHTML, title, tags, image,innerTitle}) => {

    return (
        <>
            <Modal show={showCardModal} contentClassName="contact-modal" size="xl" onHide={handleCloseCardModal}>
                <Card>

                    <div>
                        <div className="greyout">
                            <h1>{title ? title : innerTitle}</h1>
                            <div style={{display:'flex', flexWrap: 'wrap'}}>
                                {
                                    tags.map((tag, i) => {
                                        return <div key={i} className={"pill-button pill-selected smallish"}>{tag}</div>
                                    })
                                }

                            </div>

                        </div>
                        {
                            image && <img className="paragraph-img" src={require("../Assets/" + image)} alt="Logo" />
                        }
                    </div>
                    <CloseButton onClick={handleCloseCardModal} variant="white"  style={{position: 'absolute',right: '10px',top: '10px'}} />
                    <Card.Body style={{display:'flex', flexDirection:'column', padding:"5% 10%"}}>
                        {bodyHTML}
                    </Card.Body>
                </Card>
            </Modal>
        </>
    )
}