import { Glitch } from "./Glitch"
import ProfilePic from "../Assets/profile-pic-2.png"
import WhiteSignature from "../Assets/Signature-white.png"
import { Socials } from "./Socials"


export const Header = () => {
    return (
        <Glitch>
            <nav>
                <div>
                    <img className="profile-pic" src={ProfilePic} alt="Logo" />
                    <img className="signature" src={WhiteSignature} alt="Logo" />
                </div>

                <Socials></Socials>


            </nav>

        </Glitch>
    )
}