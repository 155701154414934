import { useRef, useState } from "react"
import { FloatingLabel, Form, Modal } from "react-bootstrap"
import ReactLoading from 'react-loading';
import { CheckAll, XLg } from 'react-bootstrap-icons';
import ReCAPTCHA from "react-google-recaptcha";
import ReactGA from "react-ga";

export const ContactModal = ({showContactModal, handleCloseContactModal}) => {
    const formRef = useRef(null)
    const recaptchaRef = useRef(null)

    const [loading, setLoading] = useState(false)
    const [emailError, setEmailError] = useState(false)
    const [nameError, setNameError] = useState(false)
    const [messageError, setMessageError] = useState(false)
    const [messageDelivered, setMessageDelivered] = useState(false)
    const [recaptchaError, setRecaptchaError] = useState(false)
    const [failedToSendEmail, setFailedToSendEmail] = useState(false)


    const submitContactForm = (e) => {
        e.preventDefault()
        const token = window.grecaptcha.getResponse()
        const name = formRef.current[0].value
        const email = formRef.current[1].value
        const comment = formRef.current[2].value
        if (!name) {
            setNameError(true)
            // setError("Please provide a name.")
        }
        else if (!email || !email.includes("@") || !email.includes(".")) {
            // setError("Please provide a valid email.")
            setEmailError(true)
        }
        else if (!comment) {
            // setError("Please send a little message.")
            setMessageError(true)
        }
        else
         if(!token){
            setRecaptchaError(true)
        }
        else {
            setLoading(true)
            fetch("https://landon-portfolio-api.herokuapp.com/contact", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                }, 
                body: JSON.stringify({name: name, email: email, comment: comment, recaptchaToken:token})
            }).then(res => res.json()).then(response => {
                setLoading(false)
                formRef.current.reset();
                window.grecaptcha.reset()
                if(response.success){
                    ReactGA.event({
                        category:"API interaction",
                        action: "post - /contact",
                        label: "success"
                    })
                    setMessageDelivered(true)
                    setTimeout(() => {                
                        setMessageDelivered(false)
                    }, 10000);
                }
                else {
                    ReactGA.event({
                        category:"API interaction",
                        action: "post - /contact",
                        label: "fail - response"
                    })
                    setFailedToSendEmail(true)
                    setTimeout(() => {                
                        setFailedToSendEmail(false)
                    }, 10000);
                }
            }).catch(e => {
                window.grecaptcha.reset()
                ReactGA.event({
                    category:"API interaction",
                    action: "post - /contact",
                    label: "fail - connection"
                })
                setLoading(false)
                setFailedToSendEmail(true)
                setTimeout(() => {                
                    setFailedToSendEmail(false)
                }, 10000);
            })
        }
    }


    const modalOnShow = (e) => {
        try {
            window.grecaptcha.render(recaptchaRef.current.captcha, {
                theme:'dark',
                sitekey: process.env.REACT_APP_SITE_KEY,
                callback: onchangeRecaptcha
            });
        } catch (error) {
        }
    }

    const onchangeRecaptcha = (e) => {
        setRecaptchaError(false)
    }
    return (
        <>
            <Modal onEntered={modalOnShow} show={showContactModal} contentClassName="contact-modal" size="lg" onHide={handleCloseContactModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Hit me up!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Feel free to contact me if you have any questions, a project you would like to discuss or if you would like to request my resume. </p>
                    {/* {
                        error && <div>
                            {error}
                        </div>
                    } */}
                    <Form onChange={() => {setEmailError(false); setNameError(false); setRecaptchaError(false); setMessageError(false)}} ref={formRef}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                            <FloatingLabel controlId="floatingTextarea2" style={nameError ? {color:"#FD4345"} : {}} label={nameError ? "Name - please provide a name": "Name"}>
                                <Form.Control className={nameError ? "border-red" : ""} required type="input" placeholder="Enter email" />
                            </FloatingLabel>
                            </Form.Group>

                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <FloatingLabel controlId="floatingTextarea2" style={emailError ? {color:"#FD4345"} : {}} label={emailError ? "Email - please provide a valid email": "Email"}>
                            <Form.Control type="email" className={emailError ? "border-red" : ""}  required placeholder="Enter email" />
                            </FloatingLabel>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <FloatingLabel controlId="floatingTextarea2" style={messageError ? {color:"#FD4345"} : {}} label={messageError ? "Message - please provide a little message": "Message"}>
                                <Form.Control
                                    required
                                    as="textarea"
                                    placeholder="Leave a message here"
                                    style={{ height: '100px' }}
                                    className={messageError ? "border-red" : ""} 
                                />
                            </FloatingLabel>
                        </Form.Group>

                        <div className={recaptchaError ? "recaptcha-error" : ""}>
                            <ReCAPTCHA 
                                ref={recaptchaRef}
                                theme="dark"
                                id="form-recaptcha"
                                sitekey={process.env.REACT_APP_SITE_KEY}
                                onChange={onchangeRecaptcha}
                            />

                            {recaptchaError && <div className="recaptcha-error-message">Please verify that you are not a robot.</div>}
                        </div>

                        <div className="submit-container">
                            {messageDelivered && <div className="elementToFadeInAndOut">Message delivered <CheckAll></CheckAll></div>}
                            {failedToSendEmail && <div className="elementToFadeInAndOut">Failed to send message, try again later <XLg></XLg></div>}
                            {!messageDelivered && !failedToSendEmail && <div></div>}
                            <div onClick={!loading? submitContactForm : () => {}} className="contact-button">Submit</div>
                        </div>

                    </Form>
                </Modal.Body>
                    {
                        loading && <div className="contact-form-loading">
                            {
                                <ReactLoading type='bars'></ReactLoading>
                            }
                        </div>
                    }
            </Modal>
        </>
    )
}