export const Glitch = ({children}) => {
    return (

        <div className="glitch-container">
            <div className="glitch-stack" style={{"--stacks": 3}}>
                <span style={{"--index": 0}}>{children}</span>
                <span style={{"--index": 1}}>{children}</span>
                <span style={{"--index": 2}}>{children}</span>
            </div>
        </div>
    )
}