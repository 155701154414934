import './App.css';
import HomePage from './Pages/HomePage';
import LinksPage from './Pages/LinksPage';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import PageNotFound from './Pages/PageNotFound';
import ReactGA from "react-ga";
import HistoryControl from './Components/HistoryControl';

ReactGA.initialize(process.env.REACT_APP_TRACKING_CODE)

function App() {
  return (
    <>
      <BrowserRouter>
        <HistoryControl></HistoryControl>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="links" element={<LinksPage />} />
          <Route path="/contact" element={<HomePage contact={true} />} />
          <Route path="*" element={<PageNotFound contact={true} />} />
        </Routes>
      </BrowserRouter>
      {/* <LinksPage></LinksPage> */}
    </>
  );
}

export default App;
