import SorryImage from "../Assets/couldntFindThePage.png"
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga";

function PageNotFound() {

  const navigate = useNavigate();

  return (
    <>
      <div className="background-image"/>
      <div className="background-gradient"/>
      <div className="links-page-container">
        <div className="links-desc">
        </div>
        <div className="link-btn-container">
            <img src={SorryImage}></img>
            <div className="link-btn"  onClick={()=> navigate("/links")} style={{fontWeight:'bold'}}>Check out my links!</div>
          {/* <div className="link-btn" onClick={()=> navigate("/")}>Portfolio</div>
          <div className="link-btn"  onClick={()=> window.open("https://github.com/tipantiza", "_blank")}>Github</div>
          <div className="link-btn"onClick={()=> window.open("https://www.instagram.com/tipantiza/", "_blank")}  >Instagram</div>
          <div className="link-btn" onClick={()=> window.open("https://open.spotify.com/user/landongt?si=9d0d3dc708ba4f4c", "_blank")} >Spotify</div> */}
        </div>
        
      </div>


    </>
  );
}

export default PageNotFound;
