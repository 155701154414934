import ProfilePic from "../Assets/profile-pic-2.png"
import CloseIcon from "../Assets/X.png"
import Open from "../Assets/open_for_work.png"
import OpenSML from "../Assets/open_for_work.png"
import { useEffect, useRef, useState } from "react"
import { Glitch } from "../Components/Glitch"
import { useNavigate } from "react-router-dom";
import { ContentCard } from "../Components/ContentCard"
import { ContactModal } from "../Components/ContactModal"
import { Header } from "../Components/Header"
import { Socials } from "../Components/Socials"
import { data } from "../Data/Content"
import ReactLoading from 'react-loading';
import ReactGA from "react-ga";

const filters = [
  "All",
  "About",
  "React",
  "HTML",
  "JS",
  "PHP",
  "CSS",
  "Unity",
  "C#",
  "PSQL",
  "Express",
  "Wordpress",
  "Mapbox",
  "Java",
  "Spring Boot"
]

function HomePage({contact}) {

  const navigate = useNavigate();

  const anchorRef = useRef();
  const filtersRef = useRef(false)

  const [selectedFilters, setSelectedFilters] = useState(['All'])
  const [openFilters, setOpenFilters] = useState(false)
  const [showNav, setShowNav] = useState(false);
  const [small, setSmall] = useState(false);
  const [firstColumn, setFirstColumn] = useState([]);
  const [secondColumn, setSecondColumn] = useState([]);
  const [thirdColumn, setThirdColumn] = useState([]);
  const [fourthColumn, setFourthColumn] = useState([]);
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if(window.innerWidth <= 767){
      setSmall(true);
    }
    window.addEventListener('resize', detectSize)
    window.addEventListener("scroll", listenToScroll);


    return () => {
      window.removeEventListener('resize', detectSize)
      window.removeEventListener("scroll", listenToScroll); 
    }
  }, [])

  useEffect(()=>{
    let filteredData = data;
    
    const firstColumnData = []
    const secondColumnData = []
    const thirdColumnData = []
    const fourthColumnData = []

    let firstSet = false;
    let secondSet = false;
    let thirdSet = false;


    filteredData.forEach((content, index) => {
      let skip = false;
      if(!selectedFilters.includes("All")){
        if(selectedFilters.some(item => content.tags.includes(item))){
          skip = false;
        }
        else {
          skip = true;

        }
      }
      if(!skip){
        if(!firstSet){
          firstColumnData.push(content)
          firstSet = true;
        }
        else if(!secondSet){
          secondColumnData.push(content)
          secondSet = true;

        }
        else if(!thirdSet){
          thirdColumnData.push(content)
          thirdSet = true;
        }
        else{
          fourthColumnData.push(content)
          firstSet = false;
          secondSet = false;
          thirdSet = false;
        }
      }
    })
    setFirstColumn(firstColumnData)
    setSecondColumn(secondColumnData)
    setThirdColumn(thirdColumnData)
    setFourthColumn(fourthColumnData)

  }, [selectedFilters])



  const handleCloseContactModal = () => {
    navigate('/')
  };
  const handleShowContactModal = () => {
    ReactGA.event({
      category: "Button",
      action: "Click",
      label: "contact"
    })
    navigate('/contact')
  };
  const filterClick = (filter) => {
    ReactGA.event({
      category:"Filter Button",
      action: "Click",
      label: filter
    })
    setLoading(true)
    filtersRef.current.scrollIntoView();
    setOpenFilters(false)
    const removeAllFilter = selectedFilters.filter(e => e !== "All")
    const containsFilter = removeAllFilter.findIndex(e => e === filter)
    if(containsFilter === -1){
      setSelectedFilters([...removeAllFilter, filter])
    }
    else {
      const newFilters = removeAllFilter.filter(e => e !== filter)
      if(newFilters.length === 0){
        setSelectedFilters(["All"])
      }
      else {
        setSelectedFilters(newFilters)

      }
    }

    setTimeout(() => {
      setLoading(false)
    }, 1000);
  }

  const detectSize = (e) => {
    if(e.target.innerWidth <= 767){
      setSmall(true);
    }
    else{
      setSmall(false)
    }
  }


  const clearAll = () => {
    ReactGA.event({
      category:"Filter Button",
      action: "Click",
      label: "clear all"
    })
    setLoading(true)
    setSelectedFilters(['All'])
    filtersRef.current.scrollIntoView();
    setOpenFilters(false)
    setTimeout(() => {
      setLoading(false)
    }, 1000);
  }

  const listenToScroll = (e) => {
    if (anchorRef.current) {
      if(anchorRef.current.getBoundingClientRect().y <= 0){
        setShowNav(true)
      }
      else{
        setShowNav(false)
        setOpenFilters(false)
      }
    }
  }

  return (
    <>
      {contact && <ContactModal showContactModal={contact} handleCloseContactModal={handleCloseContactModal}></ContactModal>}

      <div className="background-image"/>
      <div className="background-gradient"/>

      <div className="home-page">
        <Header></Header>

          <div className={showNav  ? openFilters ? "popup-nav popup-nav-open filter-open" : "popup-nav popup-nav-open": 'popup-nav'}>
            <div className="nav-header">
              <img className="profile-pic" src={ProfilePic} alt="Logo" />
              <div>
                <div className="nav-btn" onClick={()=> setOpenFilters(true)}>Filters</div>
                <div className="nav-btn" onClick={handleShowContactModal}>HMU</div>

                <img className="close-icon" src={CloseIcon} onClick={()=> setOpenFilters(false)} alt="Logo" />
                
              </div>

            </div>


             {openFilters &&<div className="filters-container">
                {
                  filters.map((filter, index) => <div key={index} onClick={() => filter === "All" ? clearAll() : filterClick(filter)} className={selectedFilters.findIndex(e => e === filter) !== -1 ?  "pill-button pill-selected pill-dark" : "pill-button pill-dark"}>{filter}</div>)
                }
              </div>}

          </div>

          <section className="top-section">
            <div className="intro-content">
              <Glitch>
                <h1>Bringing to life ideas through software development!</h1>
              </Glitch>
              <Glitch>
                <p>My name is Landon Tipantiza, a full-stack software developer. Check out a few of my public projects! From React Native apps to fun video games made with Unity! One of my personal favourites is a kid's video game that teaches the Ditidaht language.</p>
              </Glitch>
              {
                small?
                  <Glitch>
                    <img className="graffiti" src={OpenSML} alt="Logo" />
                  </Glitch>
                  : false
              }
              <div onClick={contact? handleCloseContactModal: handleShowContactModal} className="contact-button">Hit me up!</div>
            </div>

            <div>

              {
                !small?
                  <Glitch>
                    <img className="graffiti" src={Open} alt="Logo" />

                  </Glitch>
                  : false
              }

            </div>
          </section>

          <section>
            <div ref={filtersRef} style={{height:50}}></div>
            
            
            <Glitch>
              <div className="filters">
                {
                  filters.map((filter, index) => <div key={index} onClick={() => filter === "All" ? clearAll() : filterClick(filter)} className={selectedFilters.findIndex(e => e === filter) !== -1 ?  "pill-button pill-selected" : "pill-button"}>{filter}</div>)
                }
              </div>
              
            </Glitch>
            <div id="nav-anchor" ref={anchorRef}></div>
            <div className="posts-container">
              {
                loading ? 
                  <div  className="content-loading">
                    <ReactLoading type='bars'></ReactLoading>
                  </div> 
                  :
                  <>
                    <div className="column">
                      {
                        firstColumn.map((content, index) => <ContentCard key={index} content={content}></ContentCard> )
                      }
                    </div>
                    <div className="column">
                      {
                        secondColumn.map((content, index) =>  <ContentCard key={index} content={content}></ContentCard> )
                      }
                    </div>
                    <div className="column">
                      {
                        thirdColumn.map((content, index) =>  <ContentCard key={index} content={content}></ContentCard> )
                      }
                    </div>
                    <div className="column">
                      {
                        fourthColumn.map((content, index) =>  <ContentCard key={index} content={content} ></ContentCard> )
                      }
                    </div>
                  </>
                }
              
            </div>
          </section>
          
          <footer>
            <Socials></Socials>
          </footer>
      </div>
    </>
  );
}

export default HomePage;
