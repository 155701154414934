import { useEffect, useState } from "react";
import { Card } from "react-bootstrap"
import { Asterisk, InfoCircle, InfoCircleFill, StarFill } from "react-bootstrap-icons";
import { useLocation, useSearchParams } from "react-router-dom";
import { CardModal } from "./CardModal"


export const ContentCard = ({content}) => {

    let location = useLocation();

    let [searchParams, setSearchParams]  = useSearchParams();
    const [showCardModal, setShowCardModal] = useState(false);
    const handleCloseCardModal = () => {
        searchParams.delete("project")
        setSearchParams(searchParams)

        setShowCardModal(false)
    };
    const handleShowCardModal = () => {
        searchParams.set("project", content.id)
        setSearchParams(searchParams)
        setShowCardModal(true)
    };

    useEffect(() => {
        console.log(parseInt(searchParams.get("project")) )
        if(parseInt(searchParams.get("project")) === content.id){
            setShowCardModal(true)
        }
        else if(parseInt(searchParams.get("project")) !== content.id){
            setShowCardModal(false)
        }
        else if(location.search === ""){
            setShowCardModal(false)
        }
    }, [location])
    

    return (
        <>
            <CardModal handleCloseCardModal={handleCloseCardModal} bodyHTML={content.bodyHTML} image={content.openedImage} tags={content.tags} title={content.titleText} innerTitle={content.innerTitle} showCardModal={showCardModal}></CardModal>
            <div onClick={handleShowCardModal} className="post-card">
                <Card >
                    {
                        content.info &&
                        <InfoCircleFill style={{position:'absolute', zIndex:3, right:"2%", top:"1%", background:'black', borderRadius:"50%"}} size="20px" color="#FD4345"></InfoCircleFill>
                    }
                    {
                        content.coverImageSrc &&
                        <Card.Img variant="top" src={require("../Assets/" + content.coverImageSrc)} style={{borderRadius:0}}/>
                    }
                    <Card.Body>
                        <div style={{display:'flex', alignContent:'center', alignItems:'center', marginBottom:'10px'}}>
                            {
                                content.titlePNG &&
                                <img style={{width: content.width,marginRight:10,}} src={require("../Assets/" + content.titlePNG)}/>
                            }
                            {
                                content.titleText &&
                                <div style={{color:'white', height:'fit-content', fontWeight:'600', fontSize:18}}>{content.titleText}</div>
                                
                            }

                        </div>
                        <Card.Text>
                            {
                                content.description
                            }
                        </Card.Text>
                    </Card.Body>
                    <Card.Footer style={{display: 'flex', borderRadius: '0px'}}>
                        {
                            content.tags.map((tag, index) => <div key={index} className={"pill-button pill-selected smallish"}>{tag}</div>)
                        }
                    </Card.Footer>
                </Card>
            </div>

        </>
    )
}